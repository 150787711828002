import type { DateTimeFormats } from '@intlify/core-base';

import datetimeFormats from './dateTime';

const config: { datetimeFormats: DateTimeFormats; fallbackLocale: 'nl' } = {
	datetimeFormats,
	fallbackLocale: 'nl',
};

export default defineI18nConfig(() => config);
